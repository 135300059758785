.auth {
  background: rgba(10, 3, 3, 0.5);
  padding: 1rem 2rem;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #221c1c;
  position: absolute;
  max-width: 500px;
  width: 95%;
  top: 20%;
  right: 50%;
  transform: translateX(50%);
}

.auth-form {
  display: flex;
  flex-direction: column;
}

.auth-form input {
  margin: 1rem 0;
  padding: 0.75rem 0.5rem;
  border-radius: 5px;
  font-size: 1.3rem;
}
.auth-form input::placeholder {
  font-size: 1.4rem;
  opacity: 0.9;
}
.auth-form-input_container {
  display: flex;
  flex-direction: column;
}
.auth-form-input_container label {
  font-size: 1.4rem;
  margin-left: 0.25rem;
}

.warning {
  font-size: 1.3rem;
  color: red;
  font-weight: bold;
}

.submit {
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  background: #5c5c5c;
  color: white;
  border-radius: 5px;
}
.submit:hover {
  transform: translateY(2px);
  box-shadow: 0px 5px 5px #222020;
}

.toggle {
  font-size: 1.3rem;
  color: aliceblue;
  cursor: pointer;
  padding: 0;
  display: inline;
  background: none;
  margin: 1rem 0;
  border: none;
}
.toggle:hover {
  opacity: 0.7;
}

.button-container {
  display: flex;
}

.autofill-container {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  color: white;
  margin-left: 1rem;
}
.autofill-container button {
  margin-right: 1rem;
  background: tan;
  padding: 0.5rem;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  cursor: pointer;
  color: black;
}
.autofill-container button:hover {
  opacity: 0.9;
}

.loading {
  max-width: 25px;
  margin: 0 auto;
}
