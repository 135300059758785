.account-module {
  background: rgba(10, 3, 3, 0.5);
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #221c1c;
  position: absolute;
  max-width: 500px;
  width: 100%;
  top: 20%;
  right: 50%;
  transform: translateX(50%);
  font-size: 1.3rem;
  font-weight: bold;
}
.account-module h2 {
  padding: 0 0.75rem;
  font-size: 2.3rem;
}
.account-list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  color: whitesmoke;
}
.account-list li {
  padding: 1.2rem 1rem;
  cursor: pointer;
  border-top: 1px solid #4b4a4a;
}
.account-list li:hover {
  background: rgb(66, 60, 60);
}
