.player {
  width: 100%;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 auto;
  color: rgb(192, 192, 192);
}

.songInfo-container {
  display: flex;
  max-width: 150px;
  justify-content: center;
  width: 100%;
  align-items: center;
  z-index: 1;
  cursor: default;
}
.album {
  display: none;
}
.text {
  color: white;
  padding-left: 2rem;
}
.name {
  font-size: 1.6rem;
  margin-bottom: 0rem;
}
.artist {
  font-size: 1.2rem;
  opacity: 0.9;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  width: 100%;
  margin-bottom: 1rem;
}

.control:hover {
  transform: scale(1.05);
}

.control-container {
  display: flex;
  max-width: 300px;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translateY(10px);
}
.control-container button {
  color: rgb(241, 241, 241);
}
.control-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}
.control-group i {
  font-size: 2.5rem;
  margin: 0 1rem;
  cursor: pointer;
}
.control-group i:hover {
  opacity: 0.7;
}

.play {
  font-size: 4rem;
}
.progress_bar {
  display: flex;
  width: 100%;
  max-width: 650px;
  align-items: center;
  justify-content: center;
}

.progress_bar-time {
  font-size: 1.3rem;
  font-weight: 500;
  margin: 0 0.7rem;
}

@media screen and (min-width: 768px) {
  .album {
    max-width: 80px;
    width: 100%;
    margin-right: 2rem;
    display: block;
  }
  .songInfo-container {
    max-width: 300px;
  }
  .control-container {
    max-width: 100%;
    justify-content: space-between;
    flex-direction: row;
  }
  .control-group {
    width: 100%;
  }
  .text {
    padding-left: 0;
  }
}
