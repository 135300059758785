@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --songProgress: 0%;
  background: rgb(18, 18, 18);
  background: linear-gradient(
    0deg,
    rgba(18, 18, 18, 1) 69%,
    rgba(46, 6, 6, 0.975) 100%
  );
  scroll-behavior: smooth;
  cursor: default;
  --tooltip-text-color: white;
  --tooltip-background-color: rgb(41, 33, 33);
  --tooltip-margin: 40px;
  --tooltip-arrow-size: 6px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h2 {
  font-size: 2rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  color: white;
}

main {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 92vh;
  padding-bottom: 20rem;
}
.content {
  overflow-y: auto;
  min-height: 100vh;
  padding-bottom: 30rem;
  padding-top: 5rem;
  flex-grow: 1;
  position: relative;
  scroll-behavior: smooth;
}
.content-lower {
  padding-top: 5rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.sidebar {
  display: none;
}
.button-aria {
  padding: 0;
  background: none;
  border: none;
}

/* Scroll Bar */
::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  height: 40px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(49, 46, 46);
}

@media screen and (max-width: 500px) {
  .content-lower {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (min-width: 768px) {
  .content-lower {
    padding-top: 8rem;
  }
  .sidebar {
    display: block;
    max-width: 250px;
    background: black;
    height: 100vh;
  }
  .content {
    padding-top: 0;
  }
}
