.songs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  padding-bottom: 10rem;
}
.search-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;
}
.search-wrapper {
  margin-top: -2rem;
  margin-bottom: 3rem;
  z-index: 5;
}
@media screen and (min-width: 880px) {
  .search-wrapper {
    display: none;
  }
}

@media screen and (min-width: 1440px) {
  .songs-container {
    justify-content: flex-start;
  }
}
