.modal {
  position: absolute;
  background: rgb(36, 33, 33);
  border-radius: 3px;
  top: 63px;
  right: 294px;
  max-width: 200px;
  width: 100%;
  font-size: 1.3rem;
  letter-spacing: 0.5px;
  box-shadow: 0px 3px 6px 1px #141111;
}
.profile-list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  margin: 0;
  color: whitesmoke;
}
.profile-list li {
  padding: 1.2rem 1.5rem;
  cursor: pointer;
}
.profile-list li:hover {
  background: rgb(66, 60, 60);
}
.profile-list_logout {
  border-top: 1px solid #4b4a4a;
}
.profile-list a {
  text-decoration: none;
  color: whitesmoke;
}
