.similar-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.similar-page,
.similar-page > * {
  overflow-y: hidden;
}

.similar-header {
  display: flex;
  justify-content: space-between;
}

.similarSongs {
  display: grid;
  gap: 1rem;
  width: 100%;
  transition: none;
  grid-template-columns: repeat((2, 1fr));
  padding-bottom: 10rem;
}
.link {
  text-decoration: none;
  font-size: 1.7rem;
  color: rgb(189, 196, 196);
  align-self: flex-end;
  margin-bottom: 2rem;
  font-weight: bold;
}
.link:hover {
  opacity: 0.8;
  text-decoration: underline;
}

.songItem {
  height: auto;
}

@media screen and (max-width: 1000px) {
  .similarSongs {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 800px) {
  .similarSongs {
    margin: 0 2rem;
  }
}
@media screen and (min-width: 1000px) {
  .similarSongs {
    grid-template-columns: repeat(3, 1fr);
    margin: 0;
  }
}
@media screen and (min-width: 1200px) {
  .similarSongs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .similarSongs article {
    flex-shrink: 0;
  }
  .link {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 1400px) {
  .similarSongs {
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
    height: 630px;
    gap: 1rem 2.5rem;
  }
}
