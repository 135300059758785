.header {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}

.header__button-container {
  margin-left: auto;
  max-width: 200px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.button {
  padding: 0.5rem;
  background: #5c5c5c;
  color: white;
  opacity: 0.9;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.15s;
}

.button:hover {
  opacity: 1;
  transform: translateY(2px);
}

.header__playlist-name {
  font-size: 2.5rem;
  text-transform: capitalize;
  text-align: center;
  position: relative;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid rgb(151, 151, 151);
}
