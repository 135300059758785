.logo-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.logo-container img {
  max-width: 60px;
  max-height: 50px;
  width: 100%;
  margin-right: 1rem;
}
.logo-container p {
  font-weight: bold;
  font-size: 1.8rem;
}
.logo-container:hover p {
  color: rgb(116, 111, 109);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: fixed;
  width: 100%;
  padding: 1rem 1.5rem;
  padding-right: 3rem;
  background: black;
  color: rgb(177, 172, 172);
  z-index: 5;
  box-shadow: 2px 3px 7px 1px rgb(27, 16, 16);
}
.navbar .expand {
  max-width: 25px;
  max-width: 50px;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s;
}
.expand:hover {
  opacity: 0.7;
}

.navbar-list {
  list-style: none;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.15s;
  opacity: 1;
  max-height: 100vh;
  margin: 0;
  margin-top: 1rem;
}

.navbar-list li {
  padding: 1.5rem 0;
  font-size: 2rem;
  border-top: 1px solid #3d3535;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
}

.navbar a {
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
  color: whitesmoke;
  opacity: 0.8;
  transition: all 0.3s;
}
.navbar a:hover {
  opacity: 1;
}
.navbar-list i {
  margin-right: 1rem;
}

.navbar-list.collapsed {
  max-height: 0;
  opacity: 0;
  margin: 0;
  display: none;
}
.logOut {
  background: transparent;
  color: whitesmoke;
  border: 2px solid whitesmoke;
  padding: 1rem 1.5rem;
  border-radius: 15px;
  font-weight: bold;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.3s all;
  width: 100%;
  font-size: 1.4rem;
  margin-top: 1rem;
}
.logOut:hover {
  background: whitesmoke;
  color: black;
  opacity: 1;
}
.playlists {
  display: none;
}

.playlist-title {
  font-size: 1.4rem;
  margin: 1rem;
  margin-top: 2rem;
}
.playlist-list {
  list-style-type: none;
  padding: 1rem;
}
.playlist-list li {
  margin-bottom: 1.5rem;
}

.nav-copyright {
  display: none;
  font-weight: bold;
  margin-top: 1rem;
  flex-direction: column;
  align-items: center;
}
.nav-copyright p {
  opacity: 0.5;
  margin: 0;
  font-size: 1rem;
}

.nav-copyright a {
  opacity: 0.7;
  text-decoration: none;
  color: lavender;
  font-size: 1rem;
}
.nav-copyright a:hover {
  opacity: 1;
}

@media screen and (min-width: 880px) {
  .navbar {
    flex-wrap: nowrap;
    position: relative;
    max-width: 250px;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 3rem;
    height: 100vh;
    align-items: normal;
  }
  .navbar-list {
    width: auto;
    align-items: flex-start;
    border-bottom: 1px solid #3d3535;
    padding-bottom: 2rem;
  }
  .navbar-list li {
    padding: 0.75rem 1rem;
    font-size: 3rem;
    border-top: none;
    text-align: left;
  }

  .logo-container {
    margin-bottom: 2rem;
  }
  .expand {
    display: none;
  }
  .navbar-list.collapsed {
    margin-top: 1rem;
    max-height: 100vh;
    opacity: 1;
    display: flex;
  }
  .playlists {
    display: initial;
  }
  .nav-copyright {
    display: flex;
  }
}
