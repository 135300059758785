.volume-changer {
  display: flex;
  max-width: 150px;
  width: 100%;
  margin-left: 2rem;
  justify-content: center;
}
.volume-changer i {
  font-size: 2rem;
  max-width: 30px;
  cursor: pointer;
  margin-top: 0.5rem;
}

@media screen and (min-width: 768px) {
  .volume-changer {
    max-width: 170px;
  }
}
