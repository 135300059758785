.medium-card {
  display: flex;
  flex-direction: column;
  width: 200px;
  background: rgba(53, 54, 53, 0.3);
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: background 0.3s;
  color: white;
  padding: 1rem;
}

.medium-card .img {
  width: 100%;
  border-radius: 7px;
  position: relative;
  overflow: hidden;
}
.medium-card .text {
  font-size: 1.6rem;
}
.medium-card .desc {
  width: 100%;
  margin-top: 0;
  opacity: 0.7;
  font-size: 1.2rem;
}
.medium-card input {
  width: 100%;
}

.medium-card .album {
  width: 100%;
}
.play {
  position: absolute;
  z-index: 2;
  bottom: 15px;
  right: 10px;
  width: 50px;
  cursor: pointer;
}

.play-wrapper:hover .play,
.play-wrapper:focus .play {
  transform: scale(1.1);
  box-shadow: 0px 0px 10px rgb(134, 76, 76);
  border-radius: 50%;
}

.medium-card:hover {
  background: rgba(122, 113, 113, 0.3);
}

.playlist-add {
  font-size: 1.2rem;
  color: white;
  text-align: right;
  margin-bottom: auto;
  cursor: pointer;
  position: absolute;
  background: rgba(53, 54, 53, 0.8);
  top: 12px;
  left: 15px;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  box-shadow: 2px 3px 10px 1px rgb(22, 19, 19);
  border: none;
}
.playlist-unshare {
  cursor: pointer;
}
.playlist-unshare:hover {
  opacity: 0.7;
}

.playlist-add:hover,
.playlist-add:focus {
  background: rgba(53, 54, 53);
}

@media screen and (max-width: 495px) {
  .medium-card {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .medium-card {
    max-width: 220px;
  }
}
