.search-input {
  z-index: 9;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 230px;
  flex-grow: 2;
  overflow: hidden;
  padding: 1rem 1.5rem;
  color: black;
  cursor: pointer;
}
.search-input i {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
.search-input input {
  border: none;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 0 0.5rem;
}
.search-input input::placeholder {
  font-weight: bold;
  opacity: 0.8;
}

@media screen and (min-width: 768px) {
  /* .search-input {
    position: fixed;
    top: 18px;
    max-width: 350px;
  } */
}
