.topBar {
  display: none;
  position: fixed;
  width: 100%;
  height: 70px;
  padding: 1.5rem;
  background: rgb(190, 177, 177);
  z-index: 2;
  transition: all 0.15s;
}
.profile-button {
  position: absolute;
  max-width: 150px;
  width: 100%;
  right: 290px;
  top: 15px;
  background: black;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  border: none;
  padding-right: 1.4rem;
  transition: all 0.2s;
}
.profile-button p {
  font-weight: bolder;
}
.profile-button:hover {
  background: rgba(0, 0, 0, 0.6);
}
.profile-button-icon {
  background: rgb(68, 59, 59);
  padding: 1rem;
  border-radius: 50%;
  margin-left: -5px;
}
.profile-button-icon i {
  font-size: 1.6rem;
}
.profile-button-carat {
  font-size: 1.5rem;
}
.link {
  text-decoration: none;
  font-weight: bold;
  font-size: 1.5rem;
  color: white;
  justify-content: center;
}

.button-container {
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  width: 100%;
  margin-left: 1.5rem;
  cursor: pointer;
}
.mobile {
  position: absolute;
  top: 90px;
  left: 5px;
  z-index: 2;
}

.back-button {
  font-weight: bold;
  text-decoration: none;
  font-size: 2rem;
  color: black;
  display: flex;
  align-items: center;
  color: white;
  margin-right: 3rem;
}
.back-button i {
  max-width: 30px;
  margin-right: 1rem;
  transition: all 0.15s;
}
.back-button:hover i {
  transform: translateX(-2px);
}
.back-button:hover {
  opacity: 0.8;
}

@media screen and (min-width: 880px) {
  .topBar {
    display: initial;
  }
  .mobile {
    display: none;
  }
}
