.progress_bar {
  display: flex;
  width: 100%;
  max-width: 650px;
  align-items: center;
  justify-content: center;
}

.progress_bar-outer {
  position: relative;
  width: 100%;
  border-radius: 5px;
  height: 10px;
  background: rgb(241, 241, 241);
  margin: 1rem;
  overflow: hidden;
  cursor: pointer;
}
.progress_bar-outer:hover,
.range:focus .progress_bar-outer {
  transform: scale(1.05);
}
.progress_bar-time {
  font-size: 1.3rem;
  font-weight: 500;
  margin: 0 0.7rem;
}

.progress_bar-inner {
  background: rgb(14, 13, 13);
  height: 100%;
  z-index: 2;
}

/* Range Custom */

.progress_bar-outer:hover .range::-webkit-slider-thumb {
  background-color: purple;
}
.range {
  position: absolute;
  top: 0;
  width: 100%;
  height: 10px;
  z-index: 3;
  background: transparent;
  cursor: pointer;
}
.range,
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
}
.range::-webkit-slider-thumb {
  border: 0;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
  transition: background 0.1s linear;
  width: 15px;
  height: 15px;
  z-index: 1;
}
