.password input {
  margin: 1rem 0;
  padding: 0.75rem 0.5rem;
  border-radius: 5px;
  font-size: 1.3rem;
}
.password input::placeholder {
  font-size: 1.4rem;
  opacity: 0.7;
}

.warning {
  font-size: 1.3rem;
  color: red;
  font-weight: bold;
}

.submit {
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  background: #5c5c5c;
  color: white;
  border-radius: 5px;
  border: none;
  margin-right: 1rem;
}
.submit:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.submit:hover {
  transform: translateY(2px);
  box-shadow: 0px 5px 5px #222020;
}
