.playlist {
  cursor: pointer;
  margin-bottom: 1.5rem;
}

.delete-button {
  padding: 0.5rem;
  background: #5c5c5c;
  color: white;
  opacity: 0.9;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.15s;
}
.delete-button:hover {
  opacity: 1;
  transform: translateY(2px);
}
